<template>
  <div class="CurrencySettingsMain">
    <div>
      <p class="pageSettingsTitle">Currency</p>
    </div>

    <div
      v-if="isFetchCurrencyLoading"
      class="bg-preload w-full px-5"
      style="height: 50px; border-radius: 8px"
    >
      <div class="into-preload"></div>
    </div>

    <div v-else class="mt-2">
      <b-dropdown
        left
        class="CurrencySetting w-100"
        menu-class="w-100 "
        id="CurrencySetting"
        toggle-class="text-decoration-none   "
        no-caret
      >
        <template slot="button-content">
          <div
            class="d-flex align-items-center justify-content-center CurrencyTemplate"
          >
            <img
              class="mr-2"
              :src="getSelectedCurrency.flag"
              style="width: 40px; height: 30px"
            />
            <span class="pageSettingHeader" style="color: #000">{{
              getSelectedCurrency.country
            }}</span>
          </div>
        </template>

        <b-dropdown-form class="searchCountryForm">
          <b-form-group @submit.stop.prevent>
            <b-form-input
              id="searchCountry"
              size="sm"
              v-model="countrySearch"
              class="input-border-style"
              placeholder="Search Currency"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item class="CurrencyDropdownDiv">
          <div
            style="max-height: 280px; overflow: hidden scroll"
            v-if="getCountryCurrency.length > 0"
          >
            <div
              class="d-flex align-items-center mb-2 pl-2"
              v-for="(currency, index) in getCountryCurrency"
              :key="index"
              style="color: #18191c"
              @click="updateCurrency(currency)"
            >
              <img
                class="mr-2"
                :src="currency.flag"
                style="width: 40px; height: 30px"
              />
              <span class="">{{ currency.country }}</span>
            </div>
          </div>
          <div class="text-center" v-else>
            <p>No Currency found!</p>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
export default {
  data() {
    return {
      animation: {},
      countrySearch: "",

      selectedCurrencyId: null,

      CurrencyList: [],
      isFetchCurrencyLoading: true,
    };
  },
  computed: {
    ...mapGetters(["getEditorSettings", "getCurrencies"]),

    getCountryCurrency() {
      const searchTerm = this.countrySearch.toLowerCase();
      if (searchTerm.length > 0) {
        return this.CurrencyList.filter((country) => {
          return country.name.toLowerCase().includes(searchTerm);
        });
      }

      return this.CurrencyList;
    },
    getSelectedCurrency() {
      return this.CurrencyList.filter(
        (currency) => currency.id == this.selectedCurrencyId
      )[0];
    },
  },
  methods: {
    ...mapMutations(["updateSelectedCurrency"]),
    async updateCurrency(currency) {
      if(!currency) return
      try {
        this.isFetchCurrencyLoading = true;

        let data = {
          quizId: parseInt(localStorage.getItem("QuizID")),
          currency_id:currency.id
        };

        const response = await axios.post("/changeSetting", data);

        if (response.status == 200) {
          this.$toasted.show("Currency successfully updated!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.selectedCurrencyId = currency.id
          this.updateSelectedCurrency(currency);
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured while fetching currencies", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isFetchCurrencyLoading = false;
      }

    },

    async getCurrencyList() {
      try {
        this.isFetchCurrencyLoading = true;

        let data = {
          quizId: parseInt(localStorage.getItem("QuizID")),
        };

        const response = await axios.post("/loadCurrencies", data);

        if (response.status == 200) {
          this.selectedCurrencyId = response.data.data.currency_id;
          this.CurrencyList = response.data.data.currencies;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured while fetching currencies", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isFetchCurrencyLoading = false;
      }
    },
  },

  mounted() {
    this.getCurrencyList();
  },
};
</script>

<style scoped>
#CurrencySetting >>> button {
  background: #f1f1f4 !important;
  border: none;
  outline: none;
}
#CurrencySetting >>> .dropdown-item:active {
  background: #f1f1f4 !important;
}

.searchCountryForm :deep(form) {
  padding: 0px 10px !important;
  display: block;
}
</style>
